'use client';

import { MantineColorsTuple, createTheme, virtualColor } from "@mantine/core";

const myGray: MantineColorsTuple = [
    "#f3f3fe",
    "#e4e6ed",
    "#c8cad3",
    "#a9adb9",
    "#9093a4",
    "#808496",
    "#767c91",
    "#656a7e",
    "#585e72",
    "#4a5167"
];

const myRed: MantineColorsTuple = [
    '#F7D9D9',
    '#EFBDBD',
    '#E6A3A3',
    '#DA8B8B',
    '#CD7474',
    '#BE6060',
    '#AB4F4F',
    '#8D4949',
    '#714141',
    '#573838'
];

const myLight: MantineColorsTuple = [
    "#eaedea",
    "#d5dbd5",
    "#c0c9c0",
    "#abb7ac",
    "#98a698",
    "#718472",
    "#4c644e",
    "#3c4e3d",
    "#2c382d",
    "#1d241e"
];
const myDark: MantineColorsTuple = [
    "#f3f6f4",
    "#e8eae8",
    "#cbd2cc",
    "#adbaae",
    "#94a695",
    "#839984",
    "#79937b",
    "#678069",
    "#5a715c",
    "#4b634d"
]

export const theme = createTheme({
    white: '#F0F0F0',
    cursorType: 'pointer',
    colors: {
        red: myRed,
        myDark,
        myLight,
        gray: myGray,
        myPrimary: virtualColor({
            name: 'myPrimary',
            dark: 'myDark',
            light: 'myLight',
        }),
    },
    primaryColor: "myPrimary",
    defaultRadius: "lg",
});

